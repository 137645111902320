import { Modal, TextField } from '@material-ui/core';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { configs, downloadDoc, downloadImage } from '../../../assets/Config';
import '../../../assets/styles/RM.css'
import { useLogin } from '../../../services/contexts/AdminLoginContext';
import DeleteModalPage from '../../includes/DeleteModalPage';
import { useCRMContext } from '../../../services/contexts/CRMContext';
import { useKYCContext } from '../../../services/contexts/KYCContext';
import { Link } from 'react-router-dom';
import { useRMContext } from '../../../services/contexts/RMContext';
import { Autocomplete } from '@material-ui/lab';

export default function UniqueEntryPage() {

    const { remarkList, setRemarkList, getAllLatestRemarkList, getAllRemarkListByUser, downloadFile, setEditRemark, setEditRemarkData, editNewsData, getNewsDataById, setShowDeletedRemark, showDeletedRemark, getAllRemarkList, getNoRMUserList, getAllRemarkListByRM, remarkListType, setRemarkListType, setCustomer } = useCRMContext()
    const { setShowDelete, setShowSuccess, allowedFunc } = useLogin()
    const { setKycRequesterUserDetail, kycRequestersList } = useKYCContext()
    const { adminList } = useRMContext()

    const history = useHistory()

    const [deleteId, setDeleteId] = useState({})
    const [userName, setUserName] = useState("")

    const columns = [{ field: "id", headerName: "Remark ID", minWidth: 100, flex: 0.5, hide: true, filterable: false },
    {
        field: "cust_name", headerName: "Customer Name", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.user ? params.row.user.name : params.row.name
        }, valueGetter: (params) => params.row.user ? params.row.user.name : params.row.name
    },
    {
        field: "mobile", headerName: "Mobile No.", minWidth: 150, flex: 0.4, cellClassName: "break-text", renderCell: (params) => {
            return `${params.row.user ? params.row.user.mobile ? `+${params.row.user.mobile}` : "" : params.row.mobile ? `+${params.row.mobile}` : ""}`
        }, valueGetter: (params) => params.row.user ? params.row.user.mobile : params.row.mobile
    },
    { field: "call_remark", headerName: "Remark", minWidth: 200, flex: 0.5, cellClassName: "break-text" },
    { field: "last_call_date", headerName: "Last Call Date", minWidth: 100, flex: 0.5, cellClassName: "break-text" },
    {
        field: "view", headerName: " ", minWidth: 60, flex: 0.1, cellClassName: "break-text", cellClassName: "text-primary", renderCell: (params) => {
            return <a href="#" className="custom-link text-primary" onClick={(e) => {
                e.preventDefault()
                getAllRemarkListByUser(params.row.user ? params.row.user.id : params.row.id, true)
                setCustomer({
                    id: params.row.user ? params.row.user.id : params.row.id,
                    name: params.row.user ? params.row.user.name : params.row.name
                })
                // setEditRemark(true)
                setEditRemarkData(params.row)
            }}>Add Remark</a>
        }
    },
    {
        field: "rm_name", headerName: "Relationship Manager", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row.relationship_manager ? params.row.relationship_manager.name : ""
        }, valueGetter: (params) => params.row.relationship_manager ? params.row.relationship_manager.name : ""
    },
    {
        field: "distributor", headerName: "Distributor", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row && params.row.distributor && params.row.distributor.name ? params.row.distributor.name : ""
        }, valueGetter: (params) => params.row && params.row.distributor && params.row.distributor.name ? params.row.distributor.name : ""
    },
    {
        field: "distributor_email", headerName: "Distributor Email", minWidth: 200, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            return params.row && params.row.distributor && params.row.distributor.email ? params.row.distributor.email : ""
        }, valueGetter: (params) => params.row && params.row.distributor && params.row.distributor.email ? params.row.distributor.email : ""
    },
    {
        field: "distributor_mobile", headerName: "Distributor Mobile", minWidth: 200, flex: 0.5, cellClassName: "break-text", hide: true, renderCell: (params) => {
            return params.row && params.row.distributor && params.row.distributor.mobile ? `+${params.row.distributor.mobile}` : ""
        }, valueGetter: (params) => params.row && params.row.distributor && params.row.distributor.mobile ? `+${params.row.distributor.mobile}` : ""
    },
    {
        field: "cust_email", headerName: "Customer Email", minWidth: 150, flex: 0.5, cellClassName: "break-text", renderCell: (params) => {
            if (allowedFunc.includes(configs.functionList['user_management:load_user_details'])) {
                return <Link to={`/customerProfile/${params.row.user ? params.row.user.id : params.row.id}`} onClick={() => {
                    setKycRequesterUserDetail(params.row)
                }}>{params.row.user ? params.row.user.email : params.row.email}</Link>
            } else {
                return params.row.user ? params.row.user.email : params.row.email
            }
        }, valueGetter: (params) => params.row.user ? params.row.user.email : params.row.email
    },

    {
        field: "status", headerName: "KYC Status", minWidth: 100, flex: 0.3, cellClassName: "break-text", renderCell: (params) => {
            return params.row.user ? params.row.user.status : params.row.status
        }, valueGetter: (params) => params.row.user ? params.row.user.status : params.row.status, cellClassName: (params) => {
            let clas;
            if ((params.value) === configs.statusValues.rejected) {
                clas = "text-danger"
            }
            else if ((params.value) === configs.statusValues.verified) {
                clas = "text-success"
            }
            else if ((params.value) === configs.statusValues.pending) {
                clas = "text-primary"
            }
            return clas + " text-capitalize break-text"

        }
    },
    {
        field: "investable_surplus", headerName: "Investable Surplus", minWidth: 110, flex: 0.1, cellClassName: "break-text"
    },
    {
        field: "interested_in", headerName: "Interested in", minWidth: 110, flex: 0.1, cellClassName: "break-text"
    }
    ]

    useEffect(() => {
        (async () => {
            try {
                setUserName({
                    id: "",
                    name: ""
                })
                if (remarkListType === "all")
                    await getAllRemarkList()
                else if (remarkListType === "user")
                    await getAllRemarkListByUser(userName.id)
                else if (remarkListType === "rm")
                    await getAllRemarkListByRM(userName.id)
                else if (remarkListType === "none")
                    await getNoRMUserList()
                else
                    await getAllLatestRemarkList()
            } catch (error) {

            }
        })()
    }, [remarkListType])

    const handleChange = async (e, value) => {
        try {
            setRemarkList("")
            setUserName(value)
            if (remarkListType === "user")
                await getAllRemarkListByUser(value.id)
            else
                await getAllRemarkListByRM(value.id)
        } catch (error) {

        }
    }

    // role list page ui code
    return (
        <div id="newsListPage" className='d-md-block' style={{ width: "100%" }}>
            <div className="row d-flex align-items-center mt-2 ml-2 mb-1 mr-3">
                <div className="col-lg-6 col-8 m-0 p-0">
                    <p className="h4 text-custom-grey">Remark Management</p>
                    {(remarkListType === "user" || remarkListType === "rm") ?
                        <Autocomplete
                            className="f-12"
                            id="companyIP"
                            value={userName}
                            placeholder="Select User"
                            getOptionLabel={option => option.name}
                            onChange={handleChange}
                            options={remarkListType === "user" ? kycRequestersList : adminList}
                            renderInput={(params) => <TextField {...params} placeholder="Select User" className="f-12" />}
                        />
                        : null}
                </div>
                <div className="col-lg-6 col-4 text-right m-0 p-0">
                    <select className='' id="selectRemarkData" value={remarkListType} onChange={(e) => {
                        e.preventDefault()
                        setRemarkList("")
                        setRemarkListType(e.target.value)
                    }}>
                        <option value="all">All Remarks</option>
                        <option value="latest">Latest Remark</option>
                        <option value="user">User specific remark</option>
                        <option value="rm">RM specific remark</option>
                        <option value="none">No RM user</option>
                    </select>
                </div>
            </div>
            <div className='row m-0 mx-3 p-0 d-md-none d-block'>
                <a href='#' className='custom-link' onClick={(e) => {
                    e.preventDefault()
                    document.getElementById("rmCreateNews").classList.toggle('d-none')
                    document.getElementById("newsListPage").classList.toggle('d-none')
                }}><p className='m-0 p-0'>Add New Remark</p></a>
            </div>
            {remarkList ?

                <div className="nm-role-table m-2">
                    <DataGrid
                        rows={remarkList}
                        headerHeight={30}
                        rowHeight={40}
                        columns={columns}
                        resizable={true}
                        autoPageSize
                        pagination
                        components={{
                            Toolbar: GridToolbar,
                        }}

                    // onSelectionModelChange={onRowSelected}
                    />
                </div>
                : <div className="loader"></div>}
            <DeleteModalPage {...deleteId} />

        </div>
    )
}